import React, { createContext, useRef, useContext, useState } from 'react'

export const PageNavContext = createContext()





const PageNavProvider = ({ children }) => {
    const scrollRef = useRef();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    return (
        <PageNavContext.Provider value={{scrollRef, isFormSubmitted, setIsFormSubmitted}}>
            {children}
        </PageNavContext.Provider>
    )
}

const usePageNav = () => useContext(PageNavContext)
export { usePageNav }
export default PageNavProvider