import Cookies from 'browser-cookies'

const DOMAIN = (process.env.REACT_APP_API_DOMAIN) ? `${process.env.REACT_APP_API_DOMAIN}/v1` : 'http://localhost:5001/v1'

const _getUri = route => `${DOMAIN}${route}`

const uri_submitContactForm = _getUri(`/contact-form/`)

const serviceMethods = {
  submitContactForm: async (contactFormData) => {
    const res = await _superFetch(uri_submitContactForm, 'POST', contactFormData)
    return res
  }

}

const _superFetch = async (uri, method='GET', body={}) => {
  const params = {
    headers: _getAuthHeaders(),
    method,
  }
  if (typeof body === 'object' && Object.keys(body).length > 0) {
    params.body = JSON.stringify(body)
  }
  const res = await fetch(uri,params)
  return res
}

export default {
  ...serviceMethods,
  // CDN,
}

const _getAuthHeaders = () => ({
  'Content-Type': 'application/json',
})
