import React, { createContext, useState, useEffect, useContext } from 'react'
import { useResponsive } from './responsive'

export const MobileNavContext = createContext()

const MobileNavProvider = ({ children }) => {

  const [ isOpen, setIsOpen ] = useState(false)
  const {
    responsive: {
      breakpoint,
    }
  } = useResponsive()

  useEffect(()=>{
    switch(breakpoint){
      case 'desktop':
      case 'widescreen':
      case 'fullhd':
        setIsOpen(false)
      break
      default:
      break
    }
  },[breakpoint])

  return (
    <MobileNavContext.Provider
      value={{ isOpen, setIsOpen }}
    >
      {children}
    </MobileNavContext.Provider>
  )
}

const useMobileNav = () => useContext(MobileNavContext)
export default MobileNavProvider
export { useMobileNav }
