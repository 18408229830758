import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './views/Home';
import ResponsiveProvider from './contexts/responsive'
import MobileNavProvider from './contexts/mobile-nav'
import PageNavProvider from './contexts/page-nav';
import './bulma.scss';
import './App.scss';

const App = () => (
  <ResponsiveProvider>
    <PageNavProvider>
      <MobileNavProvider>
        <BrowserRouter>
          <Switch>
            <Route exact={true} path="/" component={Home} />
          </Switch>
        </BrowserRouter>
      </MobileNavProvider>
    </PageNavProvider>
  </ResponsiveProvider>
);

export default App;
