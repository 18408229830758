import React from "react";
import "./index.scss";
import { usePageNav } from "../../contexts/page-nav";
import ContactForm from "../../components/ContactForm";

function Home() {
  const { scrollRef } = usePageNav();
  const { isFormSubmitted, setIsFormSubmitted } = usePageNav();

  return (
    <div className="homeMainContainer">
      <div className="backgroundImageLayer">
        <img src="bgImage.png" id="bgImage" />
        <img src="Mini-Stars.png" id="bgStars" />
        <img src="smoke-overlay-faded.png" id="smoke-overlay" />
      </div>
      <div className="rocketLayer">
        <img src="Rocket.png" id="rocket" className="is-hidden-mobile" />
      </div>
      <div className="contentLayer">
        <div className="container p-4">
          <div className="content">
            <img src="logo_cropped.png" id="logo" />
            <div className="taglineContainer">
              <div className="" id="tagline2">
                Design and <br />
                Development <br></br>to fit your needs.
              </div>
              <div className="" id="tagline1">
                Design and <br />
                Development <br></br>to fit your needs.
              </div>
            </div>
            <div className="columns">
              <div className="column is-4">
                <p className="my-6">
                  We specialize in crafting stellar web applications tailored to
                  fit the needs of your growing enterprise. Let's join forces
                  and put your business on the map!
                </p>

                <div className="columns is-mobile is-hidden">
                  <a href=" target_blank" className="column is-narrow">
                    <img src="/socials/instagram.png" />
                  </a>
                  <div className="column is-narrow">
                    <img src="/socials/twitter.png" />
                  </div>
                  <div className="column is-narrow">
                    <img src="/socials/facebook.png" />
                  </div>
                  <div className="column is-narrow">
                    <img src="/socials/linkedin.png" />
                  </div>
                </div>
              </div>
            </div>
            <button
              className="button is-rounded is-warning getInTouchButton"
              onClick={() => {
                scrollRef.current.scrollIntoView({ behavior: "smooth" });
              }}
            >
              Get in touch!
            </button>
          </div>
          <ContactForm ref={scrollRef} submitted={isFormSubmitted} /> 
        </div>
      </div>
    </div>
  );
}

export default Home;
